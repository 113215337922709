import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Seo from "../components/seo";

const IndexPage = () => (
  <main className="max-w-screen-lg mx-auto">
    <Seo title="Monsieur Singe - Créations made in Paris" />
    <div className="w-full m-0">
      <div className="flex items-center justify-center flex-col">
        <StaticImage
          src="../images/logo.jpeg"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Monsieur Singe"
          style={{ marginBottom: `1.45rem`, width: 300 }}
        />
        <p className="text-center mb-10 w-10/12 md:w-8/12">
          Monsieur Singe est un espace de création au service de vos plus beaux
          moments de vie tout en étant solidaire à des causes qui vous
          importent.
        </p>
        <div className="flex items-center justify-around w-9/12 flex-col md:flex-row">
          <div className="w-12/12 md:w-4/12 flex items-center flex-col">
            <StaticImage
              src="../images/tie.svg"
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ width: 50, height: 30 }}
              className="mb-5 mx-5"
            />
            <p className="text-center">
              des noeuds-papillon pour mettre en valeur les plus beaux invités
              de la journée
            </p>
          </div>
          <div className="w-12/12 md:w-4/12 mt-10 md:mt-0 flex items-center flex-col">
            <StaticImage
              src="../images/envelope.svg"
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ width: 40, height: 30 }}
              className="mb-5"
            />
            <p className="text-center">
              de jolis faire-parts ou livrets, pour votre mariage ou un
              baptême... tous vos projets de papeterie
            </p>
          </div>
        </div>
      </div>
      <p className="text-center my-10">
        <span className="text-2xl">100% des bénéfices reversés</span>
        <br />
        <span className="text-xl">à l'association de votre choix</span>
      </p>
      <p className="text-lg my-10 text-center">
        Suivez-moi sur{" "}
        <a
          href="https://www.instagram.com/monsieur_singe_paris/"
          target="_blank"
          rel="noreferrer"
          class="underline"
        >
          @monsieur_singe
        </a>
      </p>
      <p className="text-lg my-10 text-center">
        Contactez-moi en DM ou{" "}
        <a
          href="mailto:contact@monsieursinge.fr"
          target="_blank"
          rel="noreferrer"
          class="underline"
        >
          contact@monsieursinge.fr
        </a>
      </p>
    </div>
  </main>
);

export default IndexPage;
